import Vue from 'vue'
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Start from '../views/Start'
import Kontakt from '../views/Kontakt'
import Impressum from '../views/Impressum'
import Verein from '../views/Verein'
import Proben from '../views/Proben'
import Akteure from '../views/Akteure'
import was_wird from '../views/was_wird'
import was_ist from '../views/was_ist'
import was_war from '../views/was_war'
import Dorian from '../views/waswar/dorian'
import PlemPlem from '../views/waswar/plemplem'
import GedaechtnisWasser from '../views/waswar/gedaechniswasser'
import Thunfischdosen from '../views/waswar/thunfischdosen'
import UngleichesPaar from '../views/waswar/ungleichespaar'
import Mueller from '../views/waswar/mueller'
import Gerald from '../views/waswar/gerald'
import Luther from '../views/waswar/luther'
import Yvonne from '../views/waswar/yvonne'
import LondonSuite from '../views/waswar/londonsuite'
import Geister from '../views/waswar/geister'
import Unterhaltungsschaden from '../views/waswar/unterhaltungsschaden'
import Sommernachtstraum from '../views/waswar/sommernachtstraum'
import Hamlet from '../views/waswar/hamlet'
import Rosenbeet from '../views/waswar/rosenbeet'
import TausendTode from '../views/waswar/1000'
import Duerrenmatt from '../views/waswar/duerrenmatt'
import Wahnsinn from '../views/waswar/wahnsinn'
import InfizierendeGefuehle from '../views/waswar/infizierendegefuehle'
import helgesLeben from '../views/waswar/helgesleben'
import drache from '../views/wasist/Drache'
import Intro from '../views/Intro'

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/',
        component: Intro
    },
    {
        path: '/Start',
        component: Start
    },
    {
        path: '/was_war',
        component: was_war
    },
    {
        path: '/was_war/Wahnsinn',
        component: Wahnsinn
    },
    {
        path: '/was_war/Duerrenmatt',
        component: Duerrenmatt
    },
    {
        path: '/was_war/1000',
        component: TausendTode
    },
    {
        path: '/was_war/Rosenbeet',
        component: Rosenbeet
    },
    {
        path: '/was_war/Hamlet',
        component: Hamlet
    },
    {
        path: '/was_war/Sommernachtstraum',
        component: Sommernachtstraum
    },
    {
        path: '/was_war/Unterhaltungsschaden',
        component: Unterhaltungsschaden
    },
    {
        path: '/was_war/Geister',
        component: Geister
    },
    {
        path: '/was_war/London_Suite',
        component: LondonSuite
    },
    {
        path: '/was_war/Yvonne',
        component: Yvonne
    },
    {
        path: '/was_war/Luther',
        component: Luther
    },
    {
        path: '/was_war/Gerald',
        component: Gerald
    },
    {
        path: '/was_war/Mueller',
        component: Mueller
    },
    {
        path: '/was_war/UngleichesPaar',
        component: UngleichesPaar
    },
    {
        path: '/was_war/Dorian',
        component: Dorian
    },
    {
        path: '/was_war/PlemPlem',
        component: PlemPlem
    },
    {
        path: '/was_war/GedaechtnisWasser',
        component: GedaechtnisWasser
    },
    {
        path: '/was_war/Thunfischdosen',
        component: Thunfischdosen
    },
    {
        path: '/was_war/InfizierendeGefuehle',
        component: InfizierendeGefuehle
    },
    {
        path: '/was_war/HelgesLeben',
        component: helgesLeben
    },
    {
        path: '/was_ist',
        component: was_ist
    },
    {
        path: '/was_ist/Drache',
        component: drache
    },
    {
        path: '/was_wird',
        component: was_wird
    },
    {
        path: '/Akteure',
        redirect: '/Akteure/aktiv'
    },
    {
        path: '/Akteure/:aktiv',
        component: Akteure
    },
    {
        path: '/Proben',
        component: Proben
    },
    {
        path: '/Verein',
        component: Verein
    },
    {
        path: '/Impressum',
        component: Impressum
    },
    {
        path: '/Kontakt',
        component: Kontakt
    },
    {path: '*', redirect: '/'}
];

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
